import axios from 'axios'

const base_url = 'https://gpt-api.gydtech.io/'

export const getRoutine = async(data) => {
  
    try {
      const response = await axios({
        method: "post",
        url: `${base_url}api/gptAdviceHair/hairRoutine`,
        headers: { "Content-Type": "application/json",
            "x-api-key": process.env.REACT_APP_GPT_CONSEIL,
            "login": process.env.REACT_APP_LOGIN_CACTUS},
        data
      });
      
      return response.data
    } catch (error) {
      console.error(error)
    }
  }

export const sendRecommendationEmail = async(recommendation, params) => {
  
    try {
      const response = await axios({
        method: "post",
        url: `http://localhost:8899/api/sendMail/`,
        headers: { "Content-Type": "application/json",
            "x-api-key": process.env.REACT_APP_GPT_CONSEIL,
            "login": process.env.REACT_APP_LOGIN_CACTUS},
        params,
        data: {
          recommendation
        }
      });
      
      return response.data
    } catch (error) {
      console.error(error)
    }
  }