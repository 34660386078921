import React, { useState, useEffect } from 'react'
import { Button, Box } from '@mui/material';
import './yesOrNot.css';
import {startingQuestions} from '../../startingQuestions.js'
import { saveResWidget } from '../../utils/utils';

const YesOrNot = (props) => {
    const { choices } = startingQuestions[props.payload.uid]
    const { setState } = props
    const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsVisible(true);
    }, 2100); // 2000 millisecondes (2 secondes)

    return () => clearTimeout(timeout);
  }, []); // Le tableau vide [] garantit que cet effet ne se déclenche qu'une fois après le montage initial


    const saveAndGoTo = (item) => {
        if(item.next === 4){
            setState(prevState => ({
                ...prevState, 'genre': item.value
            }))
        }
        saveResWidget(item.value, setState);
        props.actionProvider.next(item.next)
    }

    return (
         isVisible &&
           ( <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            {choices.map((item, index) => <Button onClick={() =>  {saveAndGoTo(item)}} key={index} variant="outlined">{item.text}</Button> )}
        </Box>)
    )
}

export default YesOrNot
