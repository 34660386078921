import React from 'react';
import { registerProfile, getToken, findProfileByEmail, relatedTo, moderation, createfollowingChat, updatefollowingChat } from '../api/service';
import { createChatBotMessage } from 'react-chatbot-kit';
import { LensTwoTone } from '@mui/icons-material';


const MessageParser = ({ children, actions }) => {

  let historyMessage = children.props.state.messages
  const setState = children.props.setState
  const states = children.props.state

  const findLastMsgBot = () => {
    console.log(historyMessage);
    let count = historyMessage.length - 1
    while (count >= 0) {
      if (historyMessage[count].type === 'bot' && historyMessage[count].payload.uid > -100)
        return count
      else count--
    }
  }

  const registerCustomer = async (email) => {

    let customer_id

    const data = {
      age: states.age,
      "app": "abbi",
      email: email,
      firstname: states.fullName.split(' ')[0] || null,
      lastname: states.fullName.split(' ')[1] || states.fullName.split(' ')[0],
      group_id: 1,
      pwd: "effimetrix"
    }

    let res = await findProfileByEmail(email)
    if (res.items.length) {
      customer_id = res.items[0].id
    }
    else {
      const tmp = await registerProfile(data)
      customer_id = tmp.fk_customer_id
    }

    const token = await getToken(customer_id)
    updateCustomer('token', token.token)

  }



  const updateCustomer = (key, value) => {
    setState(prevState => ({
      ...prevState,
      [key]: value
    }))
  }

  const parse = async (message) => {

    const lastIdBot = findLastMsgBot();
    let nextId = historyMessage[lastIdBot].payload?.next;
    let currentUid = historyMessage[lastIdBot].payload?.uid;
    let question = historyMessage[lastIdBot].message;
  
    try {

      if(currentUid !== 1) {
        const moderationResult = await moderation({
          "message": message
        });
        if (moderationResult.flagged) {
          nextId = -102
          throw new Error("Le message n a pas passer la moderation.");
        }

        const relatedToResult = await relatedTo({
          "question": question,
          "response": message
        });

        if (!relatedToResult.related) {
          nextId = -101;
          throw new Error("Le message n est pas en lien avec la question.");
        }
      }

      if (currentUid === 0) {
        updateCustomer('fullName', message);
      } else if (currentUid === 1) {
        updateCustomer('age', message);
      } else if (currentUid === 2) {
        updateCustomer('genre', message);
      } else if (currentUid === 5) {
        registerCustomer(message);
        let id = localStorage.getItem('followingCactusId')
        await updatefollowingChat({ id : id, step : true, num_step: 2 }, id);
        updateCustomer('email', message);
      }
      else{
        actions.addMessageToState(message)
      }
    } catch (error) {
      console.error("Erreur ", error);
    } finally {
      actions.next(nextId);
    }
  };
  

  return (
    <div>
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, {
          parse,
          actions,
        });
      })}
    </div>
  );
};

export default MessageParser;
