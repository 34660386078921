import React, { useState, useEffect} from 'react'
import { startingQuestions } from '../startingQuestions.js'
import { needsBot } from '../needsBot.js'
import { error } from '../error.js';
import { submit, submitBesoins, uploadPictureForHairs } from '../api/service.js'
import { appLanguage, titleNormalized } from "../select";
import {Langage, LangageHair} from "../language";
import Speech from 'speak-tts'
import { getRequest, getRequestForGptHair, replaceDashesAndUnderscores } from '../utils/helper.js';
import { haireQt } from '../hairQt.js';




const speech = new Speech()
if (speech.hasBrowserSupport()) {
  // console.log("speech synthesis supported")
  speech.init({
    'volume': 1,
    'lang': (appLanguage === 'french' ? 'fr-FR' : 'en-US'),
    'rate': 1,
    'pitch': 1,
    'voice': (appLanguage === 'french' ? 'Google français' : ((titleNormalized === 'new-pharma') ? 'Microsoft Mark - English (United States)' : 'Google US English')),
    'splitSentences': true,
    'listeners': {
      'onvoiceschanged': (voices) => {
        // console.log("Event voiceschanged", voices)
      }
    }
  }).then((data) => {
  }).catch(e => {
    console.error("An error occured while initializing : ", e)
  })
}

const getText = (key) => {
  return Langage[key][appLanguage];
}

const handleVideo = (src, text, time) => {
  let video = document.getElementById('vid');
  let video_text_span = document.getElementById('vid_text_span');
  let container_video = document.getElementById('container_vid')

  video.src = src
  video_text_span.style.animation = 'none';
  void video_text_span.offsetWidth; /* trigger reflow */
  video_text_span.style.animation = null;
  video_text_span.textContent = text
  container_video.style.transform = 'translateY(0)';
  video.play();
  setTimeout(() => {
    container_video.style.transform = 'translateY(1000%)';
  }, time)
}
const handleTTS = (message, delay = 500) => {
  if (!localStorage.getItem('MUTE_TTS') || !JSON.parse(localStorage.getItem('MUTE_TTS'))) {
    setTimeout(() => {
      let msg
      message.payload.choices ? msg = message.message + message.payload.choices.map(item => item.text).join(', ') : msg = message.message
      speech.speak({ text: replaceDashesAndUnderscores(msg) });
    }, delay);
  }
}





const ActionProvider = ({ createChatBotMessage, setState, children }) => {

  /* useEffect(() => {
    // Sélectionnez vos éléments d'entrée et de bouton ici (remplacez 'inputBar' et 'sendButton' par les références réelles)
  const inputBar = document.querySelector('.react-chatbot-kit-chat-input'); // Remplacez 'votre-input-id' par l'ID de votre élément d'entrée
  const sendButton = document.querySelector('.react-chatbot-kit-chat-btn-send');
  
  
    // Gestionnaire d'événement pour la touche "Enter" et la touche "Flèche bas"
     inputBar.addEventListener('keydown', (event) => {
      if (event.key === 'Enter') {
        //event.preventDefault();
        inputBar.blur();
      }
    }); 
  
    // Gestionnaire d'événement pour le bouton
    sendButton.addEventListener('click', () => {
      inputBar.blur();
    });
  
    // Nettoyage des gestionnaires d'événements lors du démontage du composant
    return () => {
      inputBar.removeEventListener('keydown', () => {});
      sendButton.removeEventListener('click', () => {});
    };
  }, []); */

  let app; // todo remove after salon
  const pharmaId = (window.location.href.includes('id=') ? window.location.href.split('id=')[1].split('&')[0] : '2085475');
  if(pharmaId === '2087398') {
    app = 'jess';
  } else {
    app = titleNormalized;
  }

  //const { actionProvider } = children
  const states = children.props.children.props.state
  const [needsFlag, setNeedsFlag] = useState(false)
  const [hairFlag, setHairFlag] = useState(false)
  const [needQ, setNeedQ] = useState([])
  const [needHairQ, setNeedHaireQ] = useState([])

  const getNextId = (id, type) => {
    return getQuestion(id, type).next ? getQuestion(id).next : null
  }

  const getQuestion = (id, type = 'starting') => {

    switch(type){
      case 'starting':
        return startingQuestions.filter(item => item.uid === id)[0]
      case 'error':
        return error.filter(item => item.uid === id)[0]
    }
    
  }
  
  const getQuestionNeed = (array, id) => {
    return array.filter(item => item.id === id)[0]
  }

  const getQuestionHair = (array, uid) => {
    return array.filter(item => item.uid === uid)[0]
  }

  const getNeedsQuestions = (needs) => {
    const keys = [{ key: '_BEFORE' }, ...needs, { key: '_AFTER' }];
    const array = [];
    keys.forEach(element => {
      const besoin = element.key
        .replace("PORES", 'GRAIN DE PEAU')
        .replace("REGULATION", 'BRILLANCE')
        .replace("IMPERFECTIONS", 'BOUTONS');

      const subArr = needsBot[besoin].map(q => ({ besoin, ...q }));
      array.push(subArr);
    });
    return array.flat();
  }

  const next = (id, needs, additionalText = null) => {

    let question 
    let type = 'starting'

    if(id < -100)
      type = 'error'

    question = getQuestion(id, type)
    let message
    let finalValue = null

    

    // if(id === 1){
    //   question.question = `Enchanté ${states.fullName}, quel âge as-tu ?`
    // }

    if (id === 25) {
      question.question += ` ${states.arg}.`
    }

    if (id === 33 && !needsFlag) {
      // console.log('finie 1er partie')
      let tmp = getNeedsQuestions(needs.myNeeds)
      console.log(tmp)
      const question = getQuestionNeed(tmp, 1)
      // console.log(question)
      message = createChatBotMessage(
        question.question,
        {
          payload: { choices: question.choices, uid: id, id: question.id, isSec: true },
          loading: true,
          terminateLoading: true,
          withAvatar: true,
          widget: question.widget || null,
          delay: question?.delay || 0
        }
      );
      addMessageToState(message)
      setNeedQ(tmp)
      setNeedsFlag(true)

    } else if (needsFlag) {
      let tmp = needQ
      // console.log(tmp, id)

      let question;
      if (id === 2) {
        // const questionToSkip = tmp.find(item => item.next === id);
        question = tmp.shift();
      } else if (id === -1) {
        // const questionToSkip = tmp.find(item => item.next === id);
        tmp = tmp.filter(item => item.id !== 2);
        question = tmp.shift();
      }
      question = tmp.shift();
      // console.log(question)
      setNeedQ(tmp)

      // if(tmp.length === 0){
      // setNeedsFlag(false)
      // }

      // const form = children.props.children.props.state.messages.filter(f=> f.type === "widget");
      // console.log(form[form.length-1])

      message = createChatBotMessage(
        question.question,
        {
          payload: { choices: question.choices, id: question.id, brief: question.brief, isSec: true, besoin: question.besoin, next: id },
          loading: true,
          terminateLoading: true,
          delay: question.delay || null,
          withAvatar: true,
          widget: question.widget || null
        }
      );
      setNeedQ(tmp)
      // if(tmp.length === 0)
      //   setNeedsFlag(false)

      addMessageToState(message)
    } else if (id === 45 && !hairFlag) { // todo why first one duplicate ??
      handleVideo(`assets/videos/video-5-${app}-${appLanguage}.mp4`, getText('ask_question'), 6000)

      // console.log('finie 1er partie hair')
      let tmp = haireQt;
      // console.log(tmp)
      question = getQuestionHair(tmp, 0)
      if (question.choices && question.choices.length && !question.widget) {
        question.question += ' (' + question.choices.join(', ') + ')';
      }
      message = createChatBotMessage(
        question.question,
        {
          payload: { choices: question.choices, uid: id, id: question.id, isSec: true },
          loading: true,
          terminateLoading: true,
          withAvatar: true,
          widget: question.widget || null,
          delay: question?.delay
        }
      );
      //handleTTS(message, 6000)
      addMessageToState(message)
      setNeedHaireQ(tmp)
      setHairFlag(true)

    } else if (hairFlag) {

      let tmp = needHairQ;
      
      if (!tmp.length) {
        const request = getRequest(children.props.children.props.state)
        //const routine = getRoutine(request)
        submitBesoins(request, children.props.children.props.state).then(r => r);
        return;
      }
      question = tmp.shift();
      if (question.uid === 0) {
        question = tmp.shift();
      }
      setNeedHaireQ(tmp)
      if (question.choices && question.choices.length && !question.widget) {
        question.question += ' (' + question.choices.join(', ') + ')';
      }
      message = createChatBotMessage(
        question.question,
        {
          payload: { choices: question.choices, id: question.uid, brief: question.brief, isSec: true, besoin: question.besoin, next: id },
          loading: true,
          terminateLoading: true,
          delay: question.delay || null,
          withAvatar: true,
          widget: question.widget || null,
        }
      );
      setNeedHaireQ(tmp)
      addMessageToState(message);
    } else if (question) {
      if (question.final) {
        finalValue = question.value
        setState(prevState => ({
          ...prevState,
          skinType: finalValue
        }))
        id = question.next//states.selfie ? question.next : 28
        question = getQuestion(id)

      }

      message = createChatBotMessage(
        question.question,
        {
          payload: { uid: id, id: question.id, next: getNextId(id, type) },
          loading: true,
          terminateLoading: true,
          withAvatar: true,
          delay: question.delay || null,
          widget: question.widget || null,
        }
      );
      
      addMessageToState(message)
    }

    if (id === 33) {
      submit(children.props.children.props.state.messages).then(r => r);
    }

    // if(id === 8 && titleNormalized === 'chatbot-hair' ){
    //   uploadPictureForHairs(children.props.children.props.state.messages).then(r => r);
    // }

    /* if(!message.widget){
      const allDivs = document.querySelectorAll('.react-chatbot-kit-chat-bot-message');
      const lastElement = allDivs[allDivs.length - 1];
      setTimeout(() => {
      lastElement.scrollIntoView()
      }, 3000)
    } */

    
    setTimeout(() => {
      const lastChild = document.querySelector('.react-chatbot-kit-chat-message-container').lastChild
      lastChild.scrollIntoView()
      },question?.delay ? question.delay + 3000 : 3000)

    const inputBar = document.querySelector('.react-chatbot-kit-chat-input-container');
    console.log(question);
    if(question?.widget === 'multiChoices' || question?.widget === 'yesOrNot' )
      inputBar.style.display = "none";
    else
      inputBar.style.display = "block";
    
    inputBar.blur();
  };


  const createBotMessage = (msg, options = {}) => {
    const message = createChatBotMessage(
      msg,
      options
    );
    addMessageToState(message)
  };



  // tient a jour le state du bot en ajoutant au state le dernier message recu
  const addMessageToState = (message) => {

    const icon = document.querySelector('.react-chatbot-kit-chat-btn-send-icon');
    const btn = document.querySelector('.react-chatbot-kit-chat-btn-send');
    const loading_point = document.createElement('span');
    const next_point = document.createElement('span');
    loading_point.className = 'loading-points';
    loading_point.append(next_point);
    icon.style.display = 'none';
    btn.append(loading_point);
    setTimeout(() => {
      icon.style.display = 'block';
      loading_point.remove();
    }, 1000);


    if (message.payload.uid === 0) {
      handleVideo(`/assets/videos/intro-${app}-${appLanguage}.mp4`, getText('start'), 12000)
    } else if (message.payload.uid === 4 && titleNormalized !== 'chatbot-hair') {
      handleVideo(`assets/videos/video-2-${app}-${appLanguage}.mp4`, getText('start_experience'), 9200)
    } else if (message.payload.uid === 5) {
      handleVideo(`assets/videos/video-6-${app}-${appLanguage}.mp4`, getText('renseigne_email'), 6000)
    } else if (message.payload.id === 8 && titleNormalized === 'chatbot-hair') {
      handleVideo(`assets/videos/video-7-${app}-${appLanguage}.mp4`, LangageHair['take_final_selfie'][appLanguage], 5000)
    } else {
      if (message.payload.uid !== 45) {
        //handleTTS(message)
      }
    }
    // let video = 'assets/video1.mp4';
    // if(localStorage.getItem('MY_AI_TALKING_FACE')) {
    //   video = localStorage.getItem('MY_AI_TALKING_FACE');
    // }
    // document.querySelector('#ai-video').src = video;
    // setTimeout(() => {
    //   document.querySelector('#vid').src = video;
    // }, 800);
    // speech.speak({text: message.message});
    setState(prevState => ({
      ...prevState,
      messages: [...prevState.messages, message]
    }))
  }

  return (
    <div>
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, {
          actions: { next, createBotMessage },
        });
      })}
    </div>
  );
};

export default ActionProvider;
