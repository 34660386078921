import React, { useEffect } from 'react'
import './App.css';
import Chatbot from 'react-chatbot-kit'
import 'react-chatbot-kit/build/main.css'
import config from './bot/config.js';
import MessageParser from './bot/MessageParser.js';
import ActionProvider from './bot/ActionProvider.js';
import {getPicture, getProfile, uploadPictureD_id, createfollowingChat, createOrganization} from "./api/service";
import {titleNormalized} from "./select";

function App() {

    const getAppName = (app) => {
        switch (app) {
            case 'chatbot-hair' : return 'SALON DE COIFFURE';
            case 'newpharma' : return 'NEW PHARMA';
            case 'jess' : return 'jessica simpson';
            case 'hairdresser-ca' : return 'Calculateur';
            default : return 'Cactus';
        }
    }

    useEffect(() => {
        document.querySelector('.react-chatbot-kit-chat-input').type = 'tel';
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const id = urlParams.get('id');
    
        const postData = async () => {
            if (id) {
                const number = parseInt(id, 10);
                const data = await createOrganization({galien_user_id : number, app : window.location.hostname.split('.')[0]})
                let res = await createfollowingChat({ organization_id: data.id, chat_id: null });
                localStorage.setItem('followingCactusId', res.id);
            } else {
                console.log('Le paramètre "id" n\'existe pas dans l\'URL.');
            }
        };
    
        postData();
    }, []);

    const postPicture = async (target) => {
        if (target.files) {
            if (target.files.length !== 0) {
                const file = target.files[0];
                try {
                    const result = await uploadPictureD_id(file);
                    if(result.result_url) {
                        document.getElementById('ai-conf').style.display = 'none';
                    }
                }
                catch (e) {

                }
            }
        }
    }

    let avataeImage;
    let id;
    if (window.location.href.includes('id=')) {
        id = window.location.href.split('id=')[1].split('&')[0];
    }

    const getAvatar = async (id) => {
       if(id) {
           try {
               const profile = await getProfile(id);
               document.querySelector('.react-chatbot-kit-chat-header').innerHTML = profile?.name;

               const avatar = await getPicture(profile?.pictures?.idAvatar);
               if(avatar && avatar.data) {
                   avataeImage = 'data:image/png;base64,' + avatar.data;
                   document.getElementById('avatar-logo').querySelector('img').src = avataeImage;
               }
               
           } catch {

           }
       }
        setTimeout(() => {
            document.getElementById('avatar-logo').querySelector('img').style.visibility = 'visible';
        }, id ? 0 : 1000)
    }
    getAvatar(id);

    

  return (
    <div className="App">
      <Chatbot
        config={config}
        headerText={getAppName(titleNormalized)}
        messageParser={MessageParser}
        actionProvider={ActionProvider}
        placeholderText=' '
        validator={(msg) =>  msg.length > 0}
      />
        {/*<div id={"ai-con"}>*/}
            {/*<img src="/assets/images/config.png" className={'setting'} onClick={ (e) => {document.getElementById('ai-conf').style.display = 'block'}}/>*/}
            {/*<video src={localStorage.getItem('MY_AI_TALKING_FACE') ? localStorage.getItem('MY_AI_TALKING_FACE') : "/assets/video1.mp4"} autoPlay={true} loop={true} id={"ai-video"}></video>*/}
            {/*<div id={'ai-conf'}>*/}
            {/*    <input accept="image/*"*/}
            {/*           type="file"*/}
            {/*           capture="environment"*/}
            {/*           onChange={ (e) => {postPicture(e.target, 25)}} />*/}
            {/*</div>*/}
        {/*</div>*/}
    </div>
  );
}

export default App;
